import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import BarChartSharpIcon from "@material-ui/icons/BarChartSharp";
import BuildIcon from "@material-ui/icons/Build";
import PublicIcon from "@material-ui/icons/Public";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import FormatPaintIcon from "@material-ui/icons/FormatPaint";
import HomeIcon from "@material-ui/icons/Home";
import MapIcon from "@material-ui/icons/Map";
import MenuIcon from "@material-ui/icons/Menu";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import PrintIcon from "@material-ui/icons/Print";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import SettingsIcon from "@material-ui/icons/Settings";
import StoreIcon from "@material-ui/icons/Store";
import SyncIcon from "@material-ui/icons/Sync";
import SubtitlesRoundedIcon from "@material-ui/icons/SubtitlesRounded";
import TodayIcon from "@material-ui/icons/Today";
import TuneIcon from "@material-ui/icons/Tune";
import ViewListSharpIcon from "@material-ui/icons/ViewListSharp";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import TranslateIcon from "@material-ui/icons/Translate";
import "devextreme/dist/css/dx.common.css";
import "numeral/locales/de";
import Nutrition from "../../pages/administration/settings/menuCardPlus/nutrition/nutrition";
import {
    allAdditivesAdministration,
    allDefaultPriceGroup,
    allLicense,
    allSetup,
    articleSynchronization,
    createCookingProcesses,
    hasRight,
    viewAdditives,
    viewArticles,
    viewArticleSizes,
    viewCategories,
    viewCookingProcesses,
    viewCourses,
    viewCustomers,
    viewDashboards,
    viewDefaultPriceGroup,
    viewDiets,
    viewEmployees,
    viewMediathek,
    viewOrders,
    viewOrderTypes,
    viewPhaseTypes,
    viewPreparationLocations,
    viewPriceGroups,
    viewRecipes,
    viewRoles,
    viewStoreTimes,
    viewTaxes,
    viewTheming,
    viewTools,
    viewTse,
    viewWareTypes,
    viewWeeklyPlanComponents,
    viewWeeklyPlans,
    viewWeeklyPlanSamples,
    viewWeekyPlanTemplates,
    viewWorkingstations,
    viewZipCodes,
    weeklyPlanSynchronization,
} from "../../../helpers/rights";
import { ReactComponent as CookingProcessesIcon } from "../../../icons/kochtopf_icon.svg";

import store from "../../../store";
import { lazy } from "react";
import AboutThisPage from "../../pages/administration/settings/shopSettings/aboutThisShop/aboutThisShop";
import onlineShopSettings from "../../pages/administration/settings/shopSettings/onlineShopSettings";

const MenuCardManagement = lazy(() => import("../../pages/administration/menuCard/menuCardManagement"));
const BaseSettings = lazy(() => import("../../pages/administration/settings/baseSettings/baseSettings"));
const Customers = lazy(() => import("../../pages/administration/settings/customers/customers"));
const priceGroupSettings = lazy(() =>
    import("../../pages/administration/settings/defaultPriceGroup/priceGroupSettings")
);
const License = lazy(() => import("../../pages/administration/settings/license/license"));
const Additives = lazy(() => import("../../pages/administration/settings/menuCardPlus/additives/additives"));
const ArticleSizes = lazy(() => import("../../pages/administration/settings/menuCardPlus/articleSizes/articleSizes"));
const Categories = lazy(() => import("../../pages/administration/settings/menuCardPlus/categories/categories"));
const Courses = lazy(() => import("../../pages/administration/settings/menuCardPlus/courses/courses"));
const Diets = lazy(() => import("../../pages/administration/settings/menuCardPlus/diets/diets"));
const PhaseTypes = lazy(() => import("../../pages/administration/settings/menuCardPlus/phaseTypes/phaseTypes"));
const PreparationLocations = lazy(() =>
    import("../../pages/administration/settings/menuCardPlus/preparationLocations/preparationLocations")
);
const TempIngredients = lazy(() =>
    import("../../pages/administration/settings/menuCardPlus/TempIngredients/TempIngredients")
);
const PriceGroups = lazy(() => import("../../pages/administration/settings/menuCardPlus/priceGroups/priceGroups"));
const Taxes = lazy(() => import("../../pages/administration/settings/menuCardPlus/taxes/taxes"));
const Tools = lazy(() => import("../../pages/administration/settings/menuCardPlus/tools/tools"));
const WareTypes = lazy(() => import("../../pages/administration/settings/menuCardPlus/wareTypes/wareTypes"));
const WorkingStations = lazy(() =>
    import("../../pages/administration/settings/menuCardPlus/workingStations/workingStations")
);
const StoreTimes = lazy(() => import("../../pages/administration/settings/openingTimes/storeTimes"));
const OrderTypes = lazy(() => import("../../pages/administration/settings/orderTypes/orderTypes"));
const roles = lazy(() => import("../../pages/administration/settings/roles/roles"));
const Translation = lazy(() => import("../../pages/administration/settings/translation/translation"));
const users = lazy(() => import("../../pages/administration/settings/users/users"));
const ZipCodeArea = lazy(() => import("../../pages/administration/settings/zipCodeArea/zipCodeArea"));
const weeklyPlanSamples = lazy(() =>
    import("../../pages/administration/weeklyPlanNavigation/samples/weeklyPlanSamples")
);
const Countries = lazy(() => import("../../pages/administration/settings/countries/countries"));
const WeeklyPlanComponentTemplates = lazy(() =>
    import(
        "../../pages/administration/weeklyPlanNavigation/templates/weeklyPlanComponentTemplates/weeklyPlanComponentTemplates"
    )
);
const WeeklyPlanTemplates = lazy(() =>
    import("../../pages/administration/weeklyPlanNavigation/templates/weeklyPlanTemplates/weeklyPlanTemplates")
);
const AllCookingProcessesOverview = lazy(() => import("../../pages/cookingProcess/allCookingProcessesOverview"));
const CookingProcess = lazy(() => import("../../pages/cookingProcess/cookingProcess"));
const CookingProcessIngredientList = lazy(() => import("../../pages/cookingProcess/cookingProcessIngredientList"));
const Dashboard = lazy(() => import("../../pages/dashboard/dashboard"));
const Dishes = lazy(() => import("../../pages/dishes/dishes"));
const Impressum = lazy(() => import("../../pages/impressum/impressum"));
const Media = lazy(() => import("../../pages/media/media"));
const PopupMediaExample = lazy(() => import("../../pages/media/popupMediaExample"));
const DailyOrderOverview = lazy(() => import("../../pages/orderManagement/dailyOrders/dailyOrderOverview"));
const OrderManagement = lazy(() => import("../../pages/orderManagement/orderManagement"));
const OrderOverview = lazy(() => import("../../pages/orderManagement/orderOverview"));
const Packaging = lazy(() => import("../../pages/packaging/packaging"));
const SyncHistory = lazy(() => import("../../pages/sync/menucard/syncHistory/syncHistory"));
const SyncOverview = lazy(() => import("../../pages/sync/menucard/syncOverview"));
const SyncWeeklyPlanSamplesOverview = lazy(() => import("../../pages/sync/weeklyPlan/syncWeeklyPlanSamplesOverview"));
const Shop = lazy(() => import("../../pages/theming/shop/shop"));
const System = lazy(() => import("../../pages/theming/system/system"));
const WeeklyPlans = lazy(() => import("../../pages/weeklyPlans/weeklyPlans"));
const ShopRecommendations = lazy(() =>
    import("../../pages/administration/settings/shopSettings/shopRecommendations/shopRecommendations")
);
const Reports = lazy(() => import("../../pages/administration/settings/reports/reports"));

export function getPages() {
    return [
        {
            name: "Home",
            route: "/",
            exact: true,
            hasRight: true,
            component: undefined,
            isNavigationPage: true,
            title: "Hauptmenü",
            showFavorites: true,
            navigationTiles: [
                {
                    pageId: 10,
                    title: "Navigation",
                    icon: MenuIcon,
                    hasRight: true,
                    hideTile: true,
                    navGroup: "Home",
                    isNavigationPage: false,
                    isNavToggle: true,
                },
                {
                    pageId: 20,
                    title: "Zurück",
                    icon: ArrowBackIcon,
                    hasRight: true,
                    hideTile: true,
                    navGroup: "Home",
                    isNavigationPage: false,
                },
                {
                    pageId: 30,
                    title: "Startseite",
                    icon: HomeIcon,
                    navGroup: "Home",
                    hideTile: true,
                    hasRight: true,
                    route: "/",
                    breadcrumbs: [],
                    isNavigationPage: false,
                },
                {
                    pageId: 101,
                    title: "Kunden",
                    icon: AssignmentIndIcon,
                    hasRight: hasRight(viewCustomers),
                    route: "/kunden",
                    breadcrumbs: [{ text: "Kunden", route: "/kunden" }],
                    isNavigationPage: false,
                    navGroup: "Home",
                    component: Customers,
                },
                {
                    pageId: 102,
                    title: "Aufträge",
                    icon: NoteAddIcon,
                    hasRight: hasRight(viewOrders),
                    route: "/auftraege",
                    breadcrumbs: [{ text: "Aufträge", route: "/auftraege" }],
                    text: "Aufträge",
                    expanded: false,
                    isNavigationPage: true,
                    navGroup: "Home",
                },

                {
                    pageId: 103,
                    title: "Küche",
                    icon: RestaurantMenuIcon,
                    hasRight: hasRight(createCookingProcesses),
                    route: "/kueche",
                    breadcrumbs: [{ text: "Küche", route: "/kueche" }],
                    navGroup: "Home",
                    isNavigationPage: true,
                },
                {
                    pageId: 104,
                    title: "Kasse",
                    icon: EuroSymbolIcon,
                    hasRight: false,
                    route: "/kasse",
                    breadcrumbs: [{ text: "kasse", route: "/kasse" }],
                    navGroup: "Home",
                    isNavigationPage: true,
                },
                // {
                //     pageId: 5,
                //     title: "Mitarbeiter",
                //     icon: AccountCircleIcon,
                //     hasRight: hasRight(viewEmployees),
                //     route: "/mitarbeiter",
                //     breadcrumbs: [{ text: "Mitarbeiter", route: "/mitarbeiter" }],
                //     navGroup: "Home",
                //     isNavigationPage: false,
                //     component: users,
                // },
                {
                    pageId: 106,
                    title: "Auswertungen",
                    icon: BarChartSharpIcon,
                    hasRight: hasRight(viewDashboards),
                    route: "/auswertungen",
                    breadcrumbs: [{ text: "Auswertungen", route: "/auswertungen" }],
                    navGroup: "Home",
                    isNavigationPage: false,
                    component: Dashboard,
                    hideTile: true,
                },
                {
                    pageId: 107,
                    title: "Management",
                    icon: SettingsIcon,
                    hasRight:
                        hasRight(viewArticles) ||
                        hasRight(viewTools) ||
                        hasRight(viewWorkingstations) ||
                        hasRight(viewArticleSizes) ||
                        hasRight(viewOrderTypes) ||
                        hasRight(viewDiets) ||
                        hasRight(viewCategories) ||
                        hasRight(viewPhaseTypes) ||
                        hasRight(allAdditivesAdministration) ||
                        hasRight(viewEmployees) ||
                        hasRight(viewRoles) ||
                        hasRight(viewPriceGroups) ||
                        hasRight(viewTaxes) ||
                        hasRight(viewWareTypes) ||
                        hasRight(viewCourses) ||
                        hasRight(viewZipCodes) ||
                        hasRight(articleSynchronization) ||
                        hasRight(weeklyPlanSynchronization) ||
                        hasRight(allSetup) ||
                        hasRight(allLicense) ||
                        hasRight(viewMediathek) ||
                        hasRight(viewTheming) ||
                        hasRight(allDefaultPriceGroup),
                    route: "/management",
                    breadcrumbs: [{ text: "Management", route: "/management" }],
                    navGroup: "Home",
                    isNavigationPage: true,
                },
                // {
                //     pageId: 108,
                //     title: "Tagesabschluss",
                //     icon: EventAvailableIcon,
                //     hasRight: true,
                //     route: "/tagesabschluss",
                //     breadcrumbs: [{ text: "Tagesabschluss", route: "/tagesabschluss" }],
                //     isNavigationPage: false,
                //     navGroup: "Home",
                //     component: dailyClosing,
                // },
                // {
                //     pageId: 109,
                //     title: "Kassenbuch",
                //     icon: MoneyIcon,
                //     hasRight: true,
                //     route: "/kassenbuch",
                //     breadcrumbs: [{ text: "TagesabsKassenbuchchluss", route: "/kassenbuch" }],
                //     isNavigationPage: false,
                //     navGroup: "Home",
                //     component: cashJournal,
                // },
            ],

            icon: HomeIcon,
        },
        {
            name: "Orders Navigation",
            route: "/auftraege",
            breadcrumbs: [{ text: "Aufträge", route: "/auftraege" }],
            title: "Aufträge",
            component: undefined,
            isNavigationPage: true,
            hasRight: hasRight(viewOrders),
            exact: true,
            navigationTiles: [
                {
                    pageId: 201,
                    title: "Erfassen",
                    icon: NoteAddIcon,
                    hasRight: hasRight(viewOrders),
                    route: "/auftraege/erfassen",
                    breadcrumbs: [
                        { text: "Aufträge", route: "/auftraege" },
                        { text: "Erfassen", route: "/auftraege/erfassen" },
                    ],
                    navGroup: "Aufträge",
                    expanded: false,
                    component: OrderManagement,
                },
                {
                    pageId: 202,
                    title: "Bestellverwaltung",
                    icon: ViewListSharpIcon,
                    hasRight: hasRight(viewOrders),
                    route: "/auftraege/bestellverwaltung",
                    breadcrumbs: [
                        { text: "Aufträge", route: "/auftraege" },
                        { text: "Bestellverwaltung", route: "/auftraege/bestellverwaltung" },
                    ],
                    navGroup: "Aufträge",
                    component: OrderOverview,
                    expanded: false,
                },
                {
                    pageId: 204,
                    title: "Verpackungen",
                    icon: PrintIcon,
                    hasRight: hasRight(viewOrders),
                    route: "/auftraege/verpackung",
                    breadcrumbs: [
                        { text: "Aufträge", route: "/auftraege" },
                        { text: "Verpackung", route: "/auftraege/verpackung" },
                    ],
                    navGroup: "Aufträge",
                    expanded: false,
                    component: Packaging,
                },
            ],
        },
        {
            title: "Küche",
            route: "/kueche",
            breadcrumbs: [{ text: "Küche", route: "/kueche" }],
            isNavigationPage: true,
            hasRight: hasRight(createCookingProcesses) || hasRight(viewCookingProcesses),
            exact: true,
            navigationTiles: [
                {
                    pageId: 301,
                    title: "Alle Rezepte anzeigen",
                    icon: RestaurantMenuIcon,
                    hasRight: hasRight(createCookingProcesses),
                    route: "/kueche/gerichte",
                    breadcrumbs: [
                        { text: "Küche", route: "/kueche" },
                        { text: "Gerichte", route: "/kueche/gerichte" },
                    ],
                    navGroup: "Küche",
                    component: Dishes,
                },
                {
                    pageId: 302,
                    title: "Wochenrezepte",
                    icon: CalendarTodayIcon,
                    hasRight: hasRight(createCookingProcesses),
                    route: "/kueche/kochwochenplaene",
                    breadcrumbs: [
                        { text: "Küche", route: "/kueche" },
                        { text: "Wochenrezepte", route: "/kueche/kochwochenplaene" },
                    ],
                    navGroup: "Küche",
                    component: WeeklyPlans,
                },
                {
                    pageId: 303,
                    title: "Kochprozesse",
                    icon: CookingProcessesIcon,
                    hasRight: hasRight(viewCookingProcesses),
                    route: "/kueche/kochprozesse/p",
                    exact: true,
                    breadcrumbs: [
                        { text: "Küche", route: "/kueche" },
                        { text: "Kochprozesse", route: "/kueche/kochprozesse/p" },
                    ],
                    navGroup: "Küche",
                },
                {
                    pageId: 304,
                    title: "Heute zu kochen",
                    icon: TodayIcon,
                    hasRight: hasRight(viewOrders),
                    route: "/auftraege/tagesbestellungen",
                    breadcrumbs: [
                        { text: "Küche", route: "/kueche" },
                        { text: "Heute zu kochen", route: "/kueche/tagesbestellungen" },
                    ],
                    navGroup: "Küche",
                    expanded: false,
                    component: DailyOrderOverview,
                },
            ],
        },
        {
            title: "Synchronisierung",
            route: "/management/synchronisierung",
            breadcrumbs: [
                { text: "Management", route: "/management" },
                { text: "Synchronisierung", route: "/management/synchronisierung" },
            ],
            exact: true,
            hasRight: hasRight(articleSynchronization) || hasRight(weeklyPlanSynchronization),
            isNavigationPage: true,
            navigationTiles: [
                {
                    pageId: 401,
                    title: "Artikel Sync.",
                    icon: StoreIcon,
                    exact: true,
                    hasRight: hasRight(articleSynchronization),
                    route: "/management/synchronisierung/artikel",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Synchronisierung", route: "/management/synchronisierung" },
                        { text: "Artikel", route: "/management/synchronisierung/artikel" },
                    ],
                    component: SyncOverview,
                    navGroup: "Synchronisierung",
                },
                {
                    pageId: 402,
                    title: "Speisepläne Sync.",
                    icon: CalendarTodayIcon,
                    exact: true,
                    hasRight: hasRight(weeklyPlanSynchronization),
                    route: "/management/synchronisierung/speiseplaene",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Synchronisierung", route: "/management/synchronisierung" },
                        { text: "Speisepläne", route: "/management/synchronisierung/speiseplaene" },
                    ],
                    navGroup: "Synchronisierung",
                    component: SyncWeeklyPlanSamplesOverview,
                },
                {
                    pageId: 403,
                    title: "Standardpreisgruppe",
                    icon: EuroSymbolIcon,
                    hasRight: hasRight(viewDefaultPriceGroup),
                    route: "/management/einstellungen/standardpreisgruppe",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Synchronisierung", route: "/management/synchronisierung" },
                        { text: "Standardpreisgruppe", route: "/management/synchronisierung/standardpreisgruppe" },
                    ],
                    navGroup: "Synchronisierung",
                    component: priceGroupSettings,
                },
            ],
        },
        {
            title: "SyncHistory",
            route: "/management/synchronisierung/artikel/SyncHistory",
            hasRight: hasRight(articleSynchronization) || hasRight(weeklyPlanSynchronization),
            breadcrumbs: [
                { text: "Management", route: "/management" },
                { text: "Synchronisierung", route: "/management/synchronisierung" },
                { text: "Artikel", route: "/management/synchronisierung/artikel" },
                { text: "SyncHistory", route: "/management/synchronisierung/artikel/syncHistory" },
            ],
            component: SyncHistory,
            isNavigationPage: false,
        },

        {
            title: "Impressum",
            route: "/impressum",
            hasRight: true,
            component: Impressum,
            isNavigationPage: false,
        },
        {
            route: "/kueche/kochprozesse/:type/:parentId?",
            exact: true,
            hasRight: hasRight(viewCookingProcesses),
            breadcrumbs: [
                { text: "Küche", route: "/kueche" },
                { text: "Kochprozesse", route: "/kueche/kochprozesse/p" },
            ],
            component: AllCookingProcessesOverview,
        },
        {
            title: "Kochprozess",
            hasRight: hasRight(viewCookingProcesses),
            route: "/kochprozess/:id/:phasesId/:stepsId",
            exact: true,
            breadcrumbs: [
                { text: "Küche", route: "/kueche" },
                { text: "Kochprozesse", route: "/kueche/kochprozesse/p" },
                { text: "Kochprozess", route: undefined },
            ],
            component: CookingProcess,
            isNavigationPage: false,
        },
        {
            title: "Kochprozess Zutatenliste",
            hasRight: hasRight(viewCookingProcesses),
            route: "/zutatenliste/:id/:phasesId/:stepsId",
            breadcrumbs: [
                { text: "Küche", route: "/kueche" },
                { text: "Kochprozesse", route: "/kueche/kochprozesse/p" },
                { text: "Zutatenliste", route: undefined },
            ],
            component: CookingProcessIngredientList,
            isNavigationPage: false,
        },
        {
            title: "Popup Media Beispiel",
            hasRight: false,
            route: "/popupMediaExample",
            component: PopupMediaExample,
        },
        {
            title: "Übersetzung",
            hasRight: true,
            route: "/uebersetzung",
            breadcrumbs: [{ text: "Übersetzung", route: "/uebersetzung" }],
            component: Translation,
        },
        {
            title: "Management",
            isNavigationPage: true,
            route: "/management",
            hasRight:
                hasRight(viewArticles) ||
                hasRight(viewTools) ||
                hasRight(viewWorkingstations) ||
                hasRight(viewArticleSizes) ||
                hasRight(viewOrderTypes) ||
                hasRight(viewDiets) ||
                hasRight(viewCategories) ||
                hasRight(viewPhaseTypes) ||
                hasRight(allAdditivesAdministration) ||
                hasRight(viewRecipes) ||
                hasRight(createCookingProcesses) ||
                hasRight(viewEmployees) ||
                hasRight(viewRoles) ||
                hasRight(viewPriceGroups) ||
                hasRight(viewTaxes) ||
                hasRight(viewWareTypes) ||
                hasRight(viewCourses) ||
                hasRight(viewZipCodes) ||
                hasRight(articleSynchronization) ||
                hasRight(weeklyPlanSynchronization) ||
                hasRight(allSetup) ||
                hasRight(allLicense) ||
                hasRight(viewMediathek) ||
                hasRight(viewTheming) ||
                hasRight(allDefaultPriceGroup),
            breadcrumbs: [{ text: "Management", route: "/management" }],
            exact: true,
            navigationTiles: [
                {
                    pageId: 501,
                    title: "System Einstellungen",
                    icon: BuildIcon,
                    hasRight:
                        hasRight(allSetup) ||
                        hasRight(allLicense) ||
                        hasRight(viewMediathek) ||
                        hasRight(viewTheming) ||
                        hasRight(allDefaultPriceGroup),
                    route: "/management/einstellungen/",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "System Einstellungen", route: "/management/einstellungen" },
                    ],
                    exact: true,
                    navGroup: "Management",
                },
                {
                    pageId: 502,
                    title: "Verwaltung Küche",
                    icon: StoreIcon,
                    hasRight:
                        hasRight(viewArticles) ||
                        hasRight(viewTools) ||
                        hasRight(viewWorkingstations) ||
                        hasRight(viewArticleSizes) ||
                        hasRight(viewOrderTypes) ||
                        hasRight(viewDiets) ||
                        hasRight(viewCategories) ||
                        hasRight(viewPhaseTypes) ||
                        hasRight(viewAdditives) ||
                        hasRight(viewPreparationLocations) ||
                        hasRight(viewWeeklyPlanComponents) ||
                        hasRight(viewWeekyPlanTemplates) ||
                        hasRight(viewWeeklyPlanSamples) ||
                        hasRight(weeklyPlanSynchronization),
                    route: "/management/kueche",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                    ],
                    exact: true,
                    navGroup: "Management",
                },
                {
                    pageId: 503,
                    title: "Verwaltung Mitarbeiter",
                    icon: AccountCircleIcon,
                    hasRight: hasRight(viewEmployees) || hasRight(viewRoles),
                    route: "/management/mitarbeiterverwaltung",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Mitarbeiter", route: "/management/mitarbeiter" },
                    ],
                    navGroup: "Management",
                },
                {
                    pageId: 504,
                    title: "Verwaltung Kasse",
                    icon: EuroSymbolIcon,
                    hasRight:
                        hasRight(viewPriceGroups) ||
                        hasRight(viewTaxes) ||
                        hasRight(viewWareTypes) ||
                        hasRight(viewCourses) ||
                        hasRight(viewZipCodes),
                    route: "/management/kasse",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Kasse", route: "/management/kasse" },
                    ],
                    navGroup: "Management",
                },
                {
                    pageId: 505,
                    title: "Synchronisierung",
                    icon: SyncIcon,
                    hasRight: hasRight(articleSynchronization) || hasRight(weeklyPlanSynchronization),
                    exact: true,

                    route: "/management/synchronisierung",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Synchronisierung", route: "/management/synchronisierung" },
                    ],
                    navGroup: "Management",
                },
                {
                    pageId: 506,
                    title: "Mediathek",
                    icon: PermMediaIcon,
                    hasRight: hasRight(viewMediathek),
                    route: "/management/mediathek",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Mediathek", route: "/management/mediathek" },
                    ],
                    navGroup: "Management",
                    component: Media,
                },
                {
                    pageId: 506,
                    title: "Shop Einstellungen",
                    icon: StoreIcon,
                    hasRight: hasRight(allSetup),
                    exact: true,
                    route: "/management/shopeinstellungen",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Shop Einstellungen", route: "/management/shopeinstellungen" },
                    ],
                    navGroup: "Management",
                    component: onlineShopSettings,
                },
                {
                    pageId: 507,
                    title: "Reports",
                    icon: SubtitlesRoundedIcon,
                    hasRight: true,
                    route: "/management/reports",
                    exact: true,
                    hideInFavorites: true,
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Reports", route: "/management/reports" },
                    ],
                    navGroup: "Management",
                    component: Reports,
                },
            ],
        },
        {
            title: "Verwaltung Speiseplan",
            route: "/management/kueche/speiseplan",
            breadcrumbs: [
                { text: "Management", route: "/management" },
                { text: "Verwaltung Küche", route: "/management/kueche" },
                { text: "Verwaltung Speiseplan", route: "/management/kueche/speiseplan" },
            ],
            exact: true,
            hasRight:
                hasRight(viewWeeklyPlanComponents) ||
                hasRight(viewWeekyPlanTemplates) ||
                hasRight(viewWeeklyPlanSamples) ||
                hasRight(viewWeeklyPlans) ||
                hasRight(articleSynchronization) ||
                hasRight(weeklyPlanSynchronization),
            isNavigationPage: true,
            navigationTiles: [
                {
                    pageId: 506,
                    title: "Speiseplanstruktur",
                    route: "/management/kueche/speiseplan/speiseplanstruktur",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Verwaltung Speiseplan", route: "/management/kueche/speiseplan" },
                        { text: "Speiseplanstruktur", route: "/management/kueche/speiseplan/speiseplanstruktur" },
                    ],
                    icon: TodayIcon,
                    hasRight: hasRight(viewWeeklyPlanComponents),
                    navGroup: "Verwaltung Speiseplan",
                    component: WeeklyPlanComponentTemplates,
                },
                {
                    pageId: 507,
                    title: "Speiseplanvorlagen",
                    route: "/management/kueche/speiseplan/speiseplanvorlagen",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Verwaltung Speiseplan", route: "/management/kueche/speiseplan" },
                        { text: "Speiseplanvorlagen", route: "/management/kueche/speiseplan/speiseplanvorlagen" },
                    ],
                    icon: CalendarTodayIcon,
                    hasRight: hasRight(viewWeekyPlanTemplates),
                    navGroup: "Verwaltung Speiseplan",
                    component: WeeklyPlanTemplates,
                },
                {
                    pageId: 508,
                    title: "Speisepläne",
                    route: "/management/kueche/speiseplan/speiseplaene",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Verwaltung Speiseplan", route: "/management/kueche/speiseplan" },
                        { text: "Speisepläne", route: "/management/kueche/speiseplan/speiseplaene " },
                    ],
                    icon: DateRangeIcon,
                    hasRight: hasRight(viewWeeklyPlanSamples),
                    navGroup: "Verwaltung Speiseplan",
                    component: weeklyPlanSamples,
                },
                {
                    pageId: 509,
                    title: "Wochenpläne",
                    icon: TodayIcon,
                    hasRight: hasRight(viewWeeklyPlans),
                    route: "/management/kueche/speiseplan/wochenplaene",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Verwaltung Speiseplan", route: "/management/kueche/speiseplan" },
                        { text: "Wochenpläne", route: "/management/kueche/speiseplan/wochenplaene" },
                    ],
                    navGroup: "Verwaltung Speiseplan",
                    component: WeeklyPlans,
                },
                {
                    pageId: 510,
                    title: "Synchronisierung",
                    icon: SyncIcon,
                    hasRight: hasRight(articleSynchronization) || hasRight(weeklyPlanSynchronization),
                    route: "/management/kueche/speiseplan/synchronisierung",
                    exact: true,
                    hideInFavorites: true,
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Verwaltung Speiseplan", route: "/management/kueche/speiseplan" },
                        { text: "Synchronisierung", route: "/management/kueche/speiseplan/synchronisierung" },
                    ],
                    navGroup: "Verwaltung Speiseplan",
                    component: SyncWeeklyPlanSamplesOverview,
                },
            ],
        },

        {
            title: "Verwaltung Kasse",
            route: "/management/kasse",
            breadcrumbs: [
                { text: "Management", route: "/management" },
                { text: "Verwaltung Kasse", route: "/management/kasse" },
            ],
            isNavigationPage: true,
            hasRight:
                hasRight(viewPriceGroups) ||
                hasRight(viewTaxes) ||
                hasRight(viewWareTypes) ||
                hasRight(viewCourses) ||
                hasRight(viewZipCodes) ||
                hasRight(viewTse),
            exact: true,
            navigationTiles: [
                {
                    pageId: 601,
                    title: "Preisgruppen",
                    route: "/management/kasse/preisgruppen",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Kasse", route: "/management/kasse" },
                        { text: "Preisgruppen", route: "/management/kasse/preisgruppen" },
                    ],
                    icon: FontAwesomeIcon,
                    iconText: "money-bill-alt",
                    hasRight: hasRight(viewPriceGroups),
                    navGroup: "Verwaltung Kasse",
                    component: PriceGroups,
                },
                {
                    pageId: 602,
                    title: "Steuersätze",
                    route: "/management/kasse/steuersaetze",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Kasse", route: "/management/kasse" },
                        { text: "Steuersätze", route: "/management/kasse/steuersaetze" },
                    ],
                    icon: FontAwesomeIcon,
                    iconText: "percent",
                    hasRight: hasRight(viewTaxes),
                    component: Taxes,
                    navGroup: "Verwaltung Kasse",
                },
                {
                    pageId: 603,
                    title: "Warengruppen",
                    route: "/management/kasse/warengruppen",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Kasse", route: "/management/kasse" },
                        { text: "Warengruppen", route: "/management/kasse/warengruppen" },
                    ],
                    icon: FontAwesomeIcon,
                    iconText: "layer-group",
                    hasRight: hasRight(viewWareTypes),
                    component: WareTypes,
                    navGroup: "Verwaltung Kasse",
                },
                {
                    pageId: 604,
                    title: "Gänge",
                    route: "/management/kasse/gaenge",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Kasse", route: "/management/kasse" },
                        { text: "Gänge", route: "/management/kasse/gaenge" },
                    ],
                    icon: FontAwesomeIcon,
                    iconText: "list-ul",
                    hasRight: hasRight(viewCourses),
                    component: Courses,
                    navGroup: "Verwaltung Kasse",
                },
                {
                    pageId: 605,
                    title: "Liefergebiete",
                    route: "/management/kasse/liefergebiete",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Kasse", route: "/management/kasse" },
                        { text: "Liefergebiete", route: "/management/kasse/liefergebiete" },
                    ],
                    icon: MapIcon,
                    hasRight: hasRight(viewZipCodes),
                    component: ZipCodeArea,
                    navGroup: "Verwaltung Kasse",
                },
                // {
                //     pageId: 606,
                //     title: "TSE",
                //     route: "/management/kasse/tse",
                //     breadcrumbs: [
                //         { text: "Management", route: "/management" },
                //         { text: "Verwaltung Kasse", route: "/management/kasse" },
                //         { text: "TSE", route: "/management/kasse/tse" },
                //     ],
                //     icon: LockIcon,
                //     hasRight: hasRight(viewTse),
                //     component: Tse,
                //     navGroup: "Verwaltung Kasse",
                // },
                {
                    pageId: 607,
                    title: "Länder",
                    route: "/management/kasse/laender",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Kasse", route: "/management/kasse" },
                        { text: "Länder", route: "/management/kasse/laender" },
                    ],
                    icon: PublicIcon,
                    hasRight: hasRight(viewZipCodes),
                    component: Countries,
                    navGroup: "Verwaltung Kasse",
                },
            ],
        },
        {
            title: "Shop Einstellungen",
            route: "/management/shopeinstellungen/",
            breadcrumbs: [
                { text: "Management", route: "/management" },
                { text: "Shop Einstellungen", route: "/management/shopeinstellungen" },
            ],
            isNavigationPage: true,
            hasRight: hasRight(allSetup),
            exact: true,
            navigationTiles: [
                {
                    pageId: 701,
                    title: "Rechtliches",
                    icon: TuneIcon,
                    hasRight: hasRight(allSetup),
                    route: "/management/shopeinstellungen/impressum",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Shop Einstellungen", route: "/management/shopeinstellungen" },
                        { text: "Rechtliches", route: "/management/shopeinstellungen/impressum" },
                    ],
                    navGroup: "Shop Einstellungen",
                    component: AboutThisPage,
                },
                {
                    pageId: 702,
                    title: "Online Shop Empfehlungen",
                    icon: TuneIcon,
                    hasRight: hasRight(allSetup),
                    route: "/management/shopeinstellungen/empfehlungen",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Shop Einstellungen", route: "/management/shopeinstellungen" },
                        { text: "Online Shop Empfehlungen", route: "/management/shopeinstellungen/empfehlungen" },
                    ],
                    navGroup: "Shop Einstellungen",
                    component: ShopRecommendations,
                },
            ],
        },
        {
            title: "System Einstellungen",
            route: "/management/einstellungen/",
            breadcrumbs: [
                { text: "Management", route: "/management" },
                { text: "System Einstellungen", route: "/management/einstellungen" },
            ],
            isNavigationPage: true,
            hasRight:
                hasRight(allSetup) ||
                hasRight(allLicense) ||
                hasRight(viewMediathek) ||
                hasRight(viewTheming) ||
                hasRight(viewDefaultPriceGroup),
            exact: true,
            navigationTiles: [
                {
                    pageId: 701,
                    title: "Setup",
                    icon: TuneIcon,
                    hasRight: hasRight(allSetup),
                    route: "/management/einstellungen/setup",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "System Einstellungen", route: "/management/einstellungen" },
                        { text: "Setup", route: "/management/einstellungen/setup" },
                    ],
                    navGroup: "System Einstellungen",
                    component: BaseSettings,
                },
                {
                    pageId: 702,
                    title: "Lizenz",
                    icon: VpnKeyIcon,
                    hasRight: hasRight(allLicense),
                    route: "/management/einstellungen/lizenz",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "System Einstellungen", route: "/management/einstellungen" },
                        { text: "Lizenz", route: "/management/einstellungen/lizenz" },
                    ],
                    navGroup: "System Einstellungen",
                    component: License,
                },

                {
                    pageId: 704,
                    title: "Individualisierung",
                    icon: FontAwesomeIcon,
                    iconText: faPalette,
                    hasRight: hasRight(viewTheming),
                    route: "/management/einstellungen/theming",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "System Einstellungen", route: "/management/einstellungen" },
                        { text: "Individualisierung", route: "/management/einstellungen/theming" },
                    ],
                    navGroup: "System Einstellungen",
                },

                {
                    pageId: 705,
                    title: "Übersetzung",
                    icon: TranslateIcon,
                    hasRight: hasRight(viewDefaultPriceGroup),
                    route: "/management/einstellungen/uebersetzung",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "System Einstellungen", route: "/management/einstellungen" },
                        { text: "Übersetzung", route: "/management/einstellungen/übersetzung" },
                    ],
                    navGroup: "System Einstellungen",
                    component: Translation,
                },
            ],
        },

        {
            title: "Theming",
            route: "/management/einstellungen/theming",
            breadcrumbs: [
                { text: "Management", route: "/management" },
                { text: "System Einstellungen", route: "/management/einstellungen" },
                { text: "Individualisierung", route: "/management/einstellungen/theming" },
            ],
            exact: true,
            hasRight: hasRight(viewTheming),
            isNavigationPage: true,
            navigationTiles: [
                {
                    pageId: 801,
                    title:
                        store.getState().license &&
                        store.getState().license.data &&
                        store.getState().license.data.systemName,
                    route: "/management/einstellungen/theming/system",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "System Einstellungen", route: "/management/einstellungen" },
                        { text: "Individualisierung", route: "/management/einstellungen/theming" },
                        {
                            text:
                                store.getState().license &&
                                store.getState().license.data &&
                                store.getState().license.data.systemName,
                            route: "/management/einstellungen/theming/system",
                        },
                    ],
                    hasRight: hasRight(viewTheming),
                    icon: FormatPaintIcon,
                    navGroup: "Theming",
                    component: System,
                },
                {
                    pageId: 802,
                    title: "Online-Shop",
                    route: "/management/einstellungen/theming/shop",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "System Einstellungen", route: "/management/einstellungen" },
                        { text: "Individualisierung", route: "/management/einstellungen/theming" },
                        { text: "Shop", route: "/management/einstellungen/theming/shop" },
                    ],
                    hasRight: hasRight(viewTheming),
                    icon: StoreIcon,
                    navGroup: "Theming",
                    component: Shop,
                },
            ],
        },
        {
            title: "Öffnungszeiten",
            hasRight: hasRight(viewStoreTimes), // => hasRight an navigationTile wenn verfügbar
            route: "/management/einstellungen/oeffnungszeiten",
            isNavigationPage: false,
            component: StoreTimes,
        },
        {
            title: "Verwaltung Küche",
            exact: true,
            route: "/management/kueche",
            breadcrumbs: [
                { text: "Management", route: "/management" },
                { text: "Verwaltung Küche", route: "/management/kueche" },
            ],
            hasRight:
                hasRight(viewArticles) ||
                hasRight(viewTools) ||
                hasRight(viewWorkingstations) ||
                hasRight(viewArticleSizes) ||
                hasRight(viewOrderTypes) ||
                hasRight(viewDiets) ||
                hasRight(viewCategories) ||
                hasRight(viewPhaseTypes) ||
                hasRight(viewAdditives) ||
                hasRight(viewPreparationLocations) ||
                hasRight(viewWeeklyPlanComponents) ||
                hasRight(viewWeekyPlanTemplates) ||
                hasRight(viewWeeklyPlanSamples) ||
                hasRight(weeklyPlanSynchronization),
            isNavigationPage: true,
            navigationTiles: [
                {
                    pageId: 901,
                    title: "Verwaltung Artikel",
                    icon: StoreIcon,
                    hasRight: hasRight(viewArticles),
                    route: "/management/kueche/artikelverwaltung",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Verwaltung Artikel", route: "/management/kueche/artikelverwaltung" },
                    ],
                    navGroup: "Verwaltung Küche",
                    component: MenuCardManagement,
                },
                {
                    pageId: 902,
                    title: "Arbeitsgeräte",
                    icon: FontAwesomeIcon,
                    iconText: "utensil-spoon",
                    hasRight: hasRight(viewTools),
                    route: "/management/kueche/arbeitsgeraete",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Arbeitsgeräte", route: "/management/kueche/arbeitsgeraete" },
                    ],
                    navGroup: "Verwaltung Küche",
                    component: Tools,
                },
                {
                    pageId: 903,
                    title: "Arbeitsplätze",
                    icon: FontAwesomeIcon,
                    iconText: "network-wired",
                    hasRight: hasRight(viewWorkingstations),
                    route: "/management/kueche/arbeitsplaetze",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Arbeitsplätze", route: "/management/kueche/arbeitsplaetze" },
                    ],
                    navGroup: "Verwaltung Küche",
                    component: WorkingStations,
                },

                {
                    pageId: 904,
                    title: "Artikelgrößen",
                    icon: FontAwesomeIcon,
                    iconText: "ruler",
                    hasRight: hasRight(viewArticleSizes),
                    route: "/management/kueche/artikelgroessen",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Artikelgrößen", route: "/management/kueche/artikelgroessen" },
                    ],
                    navGroup: "Verwaltung Küche",
                    component: ArticleSizes,
                },
                {
                    pageId: 905,
                    title: "Ausgabeorte",
                    icon: FontAwesomeIcon,
                    iconText: "database",
                    hasRight: hasRight(viewOrderTypes),
                    route: "/management/kueche/ausgabeorte",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Ausgabeorte", route: "/management/kueche/ausgabeorte" },
                    ],
                    navGroup: "Verwaltung Küche",
                    component: OrderTypes,
                },
                {
                    pageId: 906,
                    title: "Ernährungsformen",
                    icon: FontAwesomeIcon,
                    iconText: "seedling",
                    hasRight: hasRight(viewDiets),
                    route: "/management/kueche/ernaehrungsformen",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Ernährungsformen", route: "/management/kueche/ernaehrungsformen" },
                    ],
                    navGroup: "Verwaltung Küche",
                    component: Diets,
                },
                {
                    pageId: 907,
                    title: "Kategorien",
                    icon: FontAwesomeIcon,
                    iconText: "tag",
                    hasRight: hasRight(viewCategories),
                    route: "/management/kueche/kategorien",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Kategorien", route: "/management/kueche/kategorien" },
                    ],
                    navGroup: "Verwaltung Küche",
                    component: Categories,
                },
                {
                    pageId: 908,
                    title: "Phasentypen",
                    icon: FontAwesomeIcon,
                    iconText: "list-ul",
                    hasRight: hasRight(viewPhaseTypes),
                    route: "/management/kueche/phasentypen",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Phasentypen", route: "/management/kueche/phasentypen" },
                    ],
                    navGroup: "Verwaltung Küche",
                    component: PhaseTypes,
                },
                {
                    pageId: 909,
                    title: "Zusatzstoffe",
                    icon: FontAwesomeIcon,
                    iconText: "capsules",
                    hasRight: hasRight(viewAdditives),
                    route: "/management/kueche/zusatzstoffe",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Zusatzstoffe", route: "/management/kueche/zusatzstoffe" },
                    ],
                    navGroup: "Verwaltung Küche",
                    component: Additives,
                },
                {
                    pageId: 910,
                    title: "Zubereitungsorte",
                    icon: FontAwesomeIcon,
                    iconText: "list-ul",
                    hasRight: hasRight(viewPreparationLocations),
                    route: "/management/kueche/zubereitungsorte",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Zubereitungsorte", route: "/management/kueche/zubereitungsorte" },
                    ],
                    navGroup: "Verwaltung Küche",
                    component: PreparationLocations,
                },

                {
                    pageId: 911,
                    title: "Verwaltung Speiseplan",
                    icon: CalendarTodayIcon,
                    hasRight:
                        hasRight(viewWeeklyPlanComponents) ||
                        hasRight(viewWeekyPlanTemplates) ||
                        hasRight(viewWeeklyPlanSamples) ||
                        hasRight(weeklyPlanSynchronization),
                    exact: true,
                    route: "/management/kueche/speiseplan",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Verwaltung Küche", route: "/management/kueche" },
                        { text: "Verwaltung Speiseplan", route: "/management/kueche/speiseplan" },
                    ],
                    navGroup: "Verwaltung Küche",
                },
                // {
                //     pageId: 912,
                //     title: "Bundes-Lebensmittel-Schlüssel",
                //     icon: CalendarTodayIcon,
                //     hasRight:
                //         //fix me
                //         hasRight(viewWeeklyPlanComponents) ||
                //         hasRight(viewWeekyPlanTemplates) ||
                //         hasRight(viewWeeklyPlanSamples) ||
                //         hasRight(weeklyPlanSynchronization),
                //     exact: true,
                //     route: "/management/kueche/bls",
                //     breadcrumbs: [
                //         { text: "Management", route: "/management" },
                //         { text: "Verwaltung Küche", route: "/management/kueche" },
                //         { text: "Bundes-Lebensmittel-Schlüssel", route: "/management/kueche/bls" },
                //     ],
                //     navGroup: "Verwaltung Küche",
                //     component: Bls,
                // },
                // {
                //     pageId: 913,
                //     title: "Nährwerte",
                //     icon: FontAwesomeIcon,
                //     hasRight: hasRight(viewWeeklyPlanComponents),
                //     exact: true,
                //     route: "/management/kueche/nutrition",
                //     breadcrumbs: [
                //         { text: "Management", route: "/management" },
                //         { text: "Verwaltung Küche", route: "/management/kueche" },
                //         { text: "Verwaltung Nährwerte", route: "/management/kueche/nutrition" },
                //     ],
                //     navGroup: "Verwaltung Küche",
                //     component: Nutrition,
                // },
                //     {
                //         pageId: 914,
                //         title: "Zwischenprodukte",
                //         icon: FontAwesomeIcon,
                //         hasRight: hasRight(viewWeeklyPlanComponents),
                //         exact: true,
                //         route: "/management/kueche/tempingredients",
                //         breadcrumbs: [
                //             { text: "Management", route: "/management" },
                //             { text: "Verwaltung Küche", route: "/management/kueche" },
                //             { text: "Verwaltung Zwischenprodukte", route: "/management/kueche/tempingredients" },
                //         ],
                //         navGroup: "Verwaltung Küche",
                //         component: TempIngredients,
                //     },
            ],
        },
        {
            title: "Verwaltung Mitarbeiter",
            route: "/management/mitarbeiterverwaltung",
            breadcrumbs: [
                { text: "Management", route: "/management" },
                { text: "Verwaltung Mitarbeiter", route: "/management/mitarbeiterverwaltung" },
            ],
            isNavigationPage: true,
            exact: true,
            hasRight: hasRight(viewEmployees) || hasRight(viewRoles),
            navigationTiles: [
                {
                    pageId: 1001,
                    title: "Mitarbeiter",
                    icon: AccountCircleIcon,
                    hasRight: hasRight(viewEmployees),
                    route: "/management/mitarbeiterverwaltung/mitarbeiter",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Mitarbeiterverwaltung", route: "/management/mitarbeiterverwaltung" },
                        { text: "Mitarbeiter", route: "/management/mitarbeiterverwaltung/mitarbeiter" },
                    ],
                    exact: true,
                    navGroup: "Verwaltung Mitarbeiter",
                    component: users,
                },

                {
                    pageId: 1002,
                    title: "Rollen",
                    icon: AccountCircleIcon,
                    hasRight: hasRight(viewRoles),
                    route: "/management/mitarbeiterverwaltung/rollen",
                    breadcrumbs: [
                        { text: "Management", route: "/management" },
                        { text: "Mitarbeiterverwaltung", route: "/management/mitarbeiterverwaltung" },
                        { text: "Rollen", route: "/management/mitarbeiterverwaltung/rollen" },
                    ],
                    exact: true,
                    navGroup: "Verwaltung Mitarbeiter",
                    component: roles,
                },
            ],
        },
    ];
}
